import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import cetakLayout from "./layouts/cetak-layout.vue";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}


function loadCetakView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/cetak/${view}.vue`)
}

function createRoute(name, path) {
  return {
    path,
    name,
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
    },
    component: loadView(name + "-page")
  }
}

function createRouteCetak(name) {
  return {
    path: `/${name}/cetak/:id`,
    name: `cetak-${name}`,
    meta: {
      requiresAuth: true,
      layout: cetakLayout,
    },
    component: loadCetakView(name + '-cetak')
  }
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    createRoute('pengguna', '/pengguna'),
    createRoute('jabatan', '/jabatan'),
    createRoute('setting', '/setting'),
    createRoute('barang', '/barang'),
    createRoute('foto_barang', '/foto_barang'),
    createRoute('brand', '/brand'),
    createRoute('bank', '/bank'),
    createRoute('giro', '/giro'),
    createRoute('cara-bayar', '/cara_bayar'),
    createRoute('gudang', '/gudang'),
    createRoute('jenis-barang', '/jenis_barang'),
    createRoute('jenis-dokumen-outlet', '/jenis_dokumen_outlet'),
    createRoute('jenis-harga-jual', '/jenis_harga_jual'),
    createRoute('kategori-barang', '/kategori_barang'),
    createRoute('rak', '/rak'),
    createRoute('jenis-pengenal', '/jenis_pengenal'),
    createRoute('sales', '/sales'),
    createRoute('outlet', '/outlet'),
    createRoute('foto_outlet', '/foto_outlet'),
    createRoute('status-bayar', '/status_bayar'),
    createRoute('status-pesanan', '/status_pesanan'),
    createRoute('tipe-outlet', '/tipe_outlet'),
    createRoute('tipe-kunjungan', '/tipe_kunjungan'),
    createRoute('jual', '/jual'),
    createRouteCetak('jual'),
    createRoute('piutang', '/piutang'),
    createRoute('bayar_piutang', '/bayar_piutang'),
    createRoute('laporan-sales', '/laporan_sales'),
    createRoute('upload-data', '/upload_data'),
    createRoute('sales-order', '/sales_order'),
    createRouteCetak('sales-order'),
    createRoute('provinsi', '/provinsi'),
    createRoute('kota', '/kota'),
    createRoute('kelurahan', '/kelurahan'),
    createRoute('kecamatan', '/kecamatan'),
    createRoute('kategori_akun', '/kategori_akun'),
    createRoute('akun', '/akun'),
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/no_akses",
      name: "no_akses",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("no-akses-page")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    return next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      if(auth.boleh(to.path, 'lihat')) {
        next()
      } else {
        next({
          name: 'no_akses'
        })
      }
    }
  } else {
    next();
  }
});

export default router;
