// import akses from "./akses";
import ApiHelper from "./helper/api-helper";
import Helper from '@/helper/helper'

// const defaultUser = {
//   nama: "sandra@example.com",
//   avatarUrl:"https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
// };

export default {
  daftar_akses: [],
  _user: null,

  loggedIn() {
    let b = !!this._user
    return b
  },

  boleh(path, akses) {
    if (this._user?.is_admin) {
      return true
    }
    let regex = new RegExp(`^/`)
    path = path.replace(regex, '')
    let itm = this.daftar_akses?.find(x => x == `${path}:${akses}`)
    return !!itm
  },

  async logIn(user_name, password, remember_me) {
    try {
      // Send request
      let user = await ApiHelper.post("login", {
        user_name,
        password,
        remember_me,
      });
      this._user = user
      this.daftar_akses = user.akses || []
      ApiHelper.TOKEN = user.token;
      localStorage.setItem('token', user.token)
      localStorage.setItem('daftar_akses', JSON.stringify(this.daftar_akses))
      localStorage.setItem('user', JSON.stringify(this._user))
      return {
        isOk: true,
        data: user,
      };
    } catch {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  },

  logOut() {
    ApiHelper.post('logout')
      .then(() => { })
      .catch(e => Helper.info(e))
      .then(() => {
        localStorage.clear()
        ApiHelper.TOKEN = ''
      })
  },

  getUser() {
    try {
      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  },

  getToken() {
    let x = localStorage.getItem('token')
    return (x || '')
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },

  restoreLogin() {
    let token = localStorage.getItem('token') || ''
    let s_akses = localStorage.getItem('daftar_akses') || '[]'
    let s_user = localStorage.getItem('user') || ''
    if (s_user.length == 0) {
      this._user = null
      this.daftar_akses = []
      ApiHelper.token = ''
    } else {
      try {
        // ApiHelper.TOKEN = user.token;
        // localStorage.setItem('token', user.token)
        // localStorage.setItem('daftar_akses', JSON.stringify(this.daftar_akses))
        // localStorage.setItem('user', JSON.stringify(this._user))
        this._user = JSON.parse(s_user)
        this.daftar_akses = JSON.parse(s_akses)
        ApiHelper.TOKEN = token;
      } catch (er) {
        console.error(er)
        this._user = null
        this.daftar_akses = []
        ApiHelper.token = ''
      }
    }
  }
};
