function createItem(text, path, icon, items) {
  return {
    text,
    path, 
    icon,
    items
  }
}

export default [
  createItem('Home', '/home', 'fa-solid fa-house'),
  createItem('Master', null, 'fa-solid fa-star', [
    createItem('Barang', '/barang'),
    createItem('Foto Barang', '/foto_barang'),
    createItem('Brand', '/brand'),
    createItem('Bank', '/bank'),
    createItem('Giro', '/giro'),
    createItem('Cara Bayar', '/cara_bayar'),
    createItem('Gudang', '/gudang'),
    createItem('Jabatan', '/jabatan'),
    createItem('Pengguna', '/pengguna'),
    createItem('Jenis Barang', '/jenis_barang'),
    createItem('Jenis Dokumen Outlet', '/jenis_dokumen_outlet'),
    createItem('Jenis Harga Jual', '/jenis_harga_jual'),
    createItem('Kategori Barang', '/kategori_barang'),
    createItem('Rak', '/rak'),
    createItem('Outlet', '/outlet'),
    createItem('Foto Outlet', '/foto_outlet'),
    createItem('Jenis Pengenal', '/jenis_pengenal'),
    createItem('Sales', '/sales'),
    createItem('Status Pembayaran', '/status_bayar'),
    createItem('Status Pesanan', '/status_pesanan'),
    createItem('Tipe Outlet', '/tipe_outlet'),
    createItem('Tipe Kunjungan', '/tipe_kunjungan'),
    createItem('Provinsi', '/provinsi'),
    createItem('Kota', '/kota'),
    createItem('Kecamatan', '/kecamatan'),
    createItem('Kelurahan', '/kelurahan'),
    createItem('Kategori Akun', '/kategori_akun'),
    createItem('Akun', '/akun'),
  ]),
  createItem('Transaksi', null, 'fa-solid fa-right-left', [
    createItem('Sales Order', '/sales_order'),
    createItem('Penjualan', '/jual'),
    createItem('Piutang', '/piutang'),
    createItem('Bayar Piutang', '/bayar_piutang'),
    createItem('Laporan Sales', '/laporan_sales'),
    createItem('Upload Data', '/upload_data'),
  ]),
  createItem('Laporan', null, 'fa-solid fa-calculator'),
  createItem('Setting', '/setting', 'fa-solid fa-gear'),
];
