import axios from "axios";

// const SERVER_URL = "http://localhost:8000/api/admin";
const SERVER_URL = "https://sf-api.sendokpos.com/api/admin";

class ApiHelper {
  static TOKEN = ''

  static baseUrl() {
    return SERVER_URL
  }

  static getImageUrl(fileName) {
    let baseUrl = this.baseUrl()
    return `${baseUrl}/file/${fileName}`
  }
  
  static post(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${SERVER_URL}/${url}`, data, {
          headers: {
            token: this.TOKEN
          }
        })
        .then((res) => {
          let api = res.data;
          if (!api.ok) {
            reject(api.msg);
          } else {
            resolve(api.data);
          }
        })
        .catch((e) => reject(e));
    });
  }
  static postFile(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${SERVER_URL}/${url}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.TOKEN
          },
        })
        .then((res) => {
          let api = res.data;
          if (!api.ok) {
            reject(api.msg);
          } else {
            resolve(api.data);
          }
        })
        .catch((e) => reject(e));
    });
  }
}

export default ApiHelper;
